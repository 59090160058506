import { environment } from './environments/environment';

export const API_VERSIONs = {
  VERSION_1_0_0: '1.0.0',
  VERSION_2_0_0: '2.0.0',
};

export const API_ENDPOINTS = {
  auth: `${environment.domain.apiAuth}`, // <<-- API AUTH
  //#region Project Admin quan ly permission
  permission_v2_file_v2: `${environment.domain.apiAuth}/permission-v2/file-v2`,
  //#endregion

  //#region KHEN THƯỞNG KỶ LUẬT
  ktkl_main: `${environment.domain.apiKhenThuongKyLuat}/ktkl`,
  ktkl_cap_truong: `${environment.domain.apiKhenThuongKyLuat}/ktkl/cap-truong`,
  ktkl_cap_khoa: `${environment.domain.apiKhenThuongKyLuat}/ktkl/cap-khoa`,
  ktkl_category: `${environment.domain.apiKhenThuongKyLuat}/category`,
  ktkl_don_vi: `${environment.domain.apiKhenThuongKyLuat}/syn-don-vi`,
  ktkl_contact_profile: `${environment.domain.apiKhenThuongKyLuat}/syn-contact-profile`,
  ktkl_category_sheet: `${environment.domain.apiKhenThuongKyLuat}/sheet`,
  ktkl_DON_VI: `${environment.domain.apiKhenThuongKyLuat}/don-vi`,
  ktkl_file: `${environment.domain.apiKhenThuongKyLuat}/ktkl-v2/file-v2`,
  ktkl_thong_ke: `${environment.domain.apiKhenThuongKyLuat}/thong-ke`,
  ktkl_thong_ke_ggsheet: `${environment.domain.apiKhenThuongKyLuat}/thong-ke-ggsheet`,
  ktkl_cai_dat_tai_khoan: `${environment.domain.apiKhenThuongKyLuat}/tai-khoan`,
  ktkl_cai_dat_don_vi: `${environment.domain.apiKhenThuongKyLuat}/don-vi`,
  ktkl_import_excel: `${environment.domain.importExcel}/import`,
  ktkl_import_excel_loai_2: `${environment.domain.importExcel}/import-excel-loai-2`,
  //#endregion
};
